import { baseErrorHandler, providerWebsiteHandler } from 'errors/ErrorHandlers'

import { api } from 'config/api'

import {
  BankName,
  EncodedProviderWebsite,
  PersistedEncodedProviderWebsite,
  PersistedEncodedProviderWebsiteProps,
  SubdomainStatus,
} from 'services/auth/ssp'

import { Endpoint } from '.'

const RESOURCE_BASE_URL = 'provider-websites'

const ProviderWebsiteEndpoints = {
  persist: (userId: string): Endpoint => ({
    method: 'PUT',
    url: `${RESOURCE_BASE_URL}/persist/${userId}`,
    errorHandler: baseErrorHandler,
  }),
  load: (userId: string, bank: string): Endpoint => ({
    method: 'GET',
    url: `${RESOURCE_BASE_URL}/load/${userId}?banks=${bank}`,
    errorHandler: providerWebsiteHandler,
  }),
  loadExternal: (userId: string): Endpoint => ({
    method: 'GET',
    url: `${RESOURCE_BASE_URL}/load-external/${userId}`,
    errorHandler: baseErrorHandler,
  }),
  setIsLive: (userId: string): Endpoint => ({
    method: 'PATCH',
    url: `${RESOURCE_BASE_URL}/set-is-live/${userId}`,
    errorHandler: baseErrorHandler,
  }),
  checkSubdomainStatus: (subdomain: string): Endpoint => ({
    method: 'GET',
    url: `${RESOURCE_BASE_URL}/check-subdomain-status?subdomain=${subdomain}`,
    errorHandler: providerWebsiteHandler,
  }),
}

export async function fetchSubdomainStatus(subdomain?: string): Promise<SubdomainStatus> {
  if (!subdomain) {
    throw new Error('Subdomain is not provided')
  }
  const response = await api.request<SubdomainStatus>(
    ProviderWebsiteEndpoints.checkSubdomainStatus(subdomain),
  )
  return response.data
}

export async function fetchExternal(userId: string): Promise<EncodedProviderWebsite> {
  if (!userId) {
    throw new Error('UserId is not provided')
  }
  const response = await api.request<EncodedProviderWebsite>(
    ProviderWebsiteEndpoints.loadExternal(userId),
  )
  return response.data
}

export async function fetchInternalBank(
  userId: string,
  bank: BankName,
): Promise<PersistedEncodedProviderWebsite | null> {
  if (!userId) {
    throw new Error('UserId is not provided')
  }
  if (!bank) {
    throw new Error('Bank is not provided')
  }
  const response = await api.request<PersistedEncodedProviderWebsiteProps>(
    ProviderWebsiteEndpoints.load(userId, bank),
  )
  return response.data.providerWebsite ?? null
}

export default ProviderWebsiteEndpoints
