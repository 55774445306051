import { Mutex } from 'async-mutex'
import { AxiosError } from 'axios'
import { AUTH_TOKEN_KEY, AUTH_TOKEN_RESTRICTED_KEY } from 'cookieStorage'
import { LOCALE_COOKIE_NAME, parseValidLocale } from 'i18n'
import { GetServerSidePropsContext, GetServerSidePropsResult } from 'next'
import { parseCookies } from 'nookies'
import { ParsedUrlQuery } from 'querystring'

import {
  cleanAuthentication,
  setupServerAuthentication,
  setupServerResponseInterceptor,
} from 'config/api'
import {
  DEFAULT_APP_SUBDOMAIN,
  ERROR_CODES,
  FALLBACK_LANG,
  LANGUAGES,
  PaletteType,
  redirectExceptions,
  ThemeNames,
  USER_ROLE,
} from 'lib/constants'
import { Location } from 'lib/meeting-location'
import { routes } from 'lib/routes'

import { UnsplashImage } from 'scenes/MyPage/WebsiteBuilder/components/unsplash'
import { MeetingRoomFromApi } from 'services/api/MeetingRooms'
import { fetchSubdomainStatus } from 'services/api/ProviderWebsite'
import { fetchProvider, Flags, ProviderUserProps, ServerUser } from 'services/api/Users'

import { fetchUserByToken } from './fetchUser'

// We are using mutex in order to guarantee createPage is single-threaded as it seems like it is not
const mutex = new Mutex()

export type CommonPageProps<T extends ParsedUrlQuery = ParsedUrlQuery> = {
  host: string
  path: string
  currentUrl: string
  jwtToken?: string
  currentSubdomain?: string
  query: T
  initialLocale: string
}
export type ServerUserProps<T extends ParsedUrlQuery = ParsedUrlQuery> = CommonPageProps<T> & {
  serverUser?: ServerUser
}
export type SubdomainStatus = {
  status: {
    user_id: string
    subdomain: string
    /**
     * True when website is published and owner has active or trial subscription
     **/
    is_live: boolean
    /**
     * True when website is published
     **/
    is_published: boolean
  }
}
export type SubdomainStatusProps = {
  subdomainStatus?: SubdomainStatus | null
}
export type EncodedBank = string

export type ImageType = string | UnsplashImage
export type Service = {
  duration: string
  image: ImageType | null
  title: string
}
export type BankName = 'primary_bank' | 'secondary_bank'
export type Bank = {
  logo: string | null
  theme: ThemeNames
  palette: PaletteType
  font: string
  services: Service[]
  homePageImage: ImageType | null
  homeImagePosition?: string
  aboutImage?: ImageType | null
  aboutImagePosition?: string
  include_bookable_services: boolean
  currency?: string
  lang?: string
  title?: string
  aboutTitle?: string
  subtitle?: string
  aboutDescription?: string
  customHomeImage?: boolean
  customAboutImage?: boolean
}
export type ProviderWebsite = {
  [key in BankName]?: Bank
}
export type EncodedProviderWebsite = {
  [key in BankName]: EncodedBank
}
export type ExternalProviderWebsite = EncodedProviderWebsite & {
  provider_subscription_plan_id?: string
}
export type PersistedProviderWebsite = ProviderWebsite & {
  user_id?: string
  is_live: boolean
  banks_are_equal: boolean
}
export type PersistedEncodedProviderWebsite = EncodedProviderWebsite & {
  user_id: string
  is_live: boolean
  banks_are_equal: boolean
}
export type ProviderWebsiteProps = {
  providerWebsite?: EncodedProviderWebsite
}
export type PersistedEncodedProviderWebsiteProps = {
  providerWebsite: PersistedEncodedProviderWebsite | null
}
export type BookableService = {
  id: string
  user_id: string | null
  name: string | null
  description: string | null
  // image might be an unsplash object (stringified), not necessarily an image source URL! This object has more than 1 url (for different size images)
  image: string | null
  price_in_cents: string | null
  iso_currency_code: string | null
  is_free: boolean
  duration: number
  is_active: boolean
  is_archived: boolean
  is_default: boolean
  edited_variation_of: string | null
  image_position: string | null
  btn_label: string | null
  btn_color: string | null
  meeting_location: Location
  additional_meeting_info: string | null
  meeting_location_room_id: string | null
  meeting_location_is_quickmeet: boolean
  meeting_room: MeetingRoomFromApi | null
  weight: number
}
export type BookableServices = BookableService[]
export type BookableServicesData = {
  bookable_services?: BookableService[]
}
export type BookableServicesProps = {
  bookableServices?: BookableService[]
}

export const populateCommonProps = async <Q extends ParsedUrlQuery>(
  ctx: GetServerSidePropsContext<Q>,
): Promise<CommonPageProps<(typeof ctx)['query']>> => {
  const host = `${ctx.req?.headers.host}`
  const currentUrl = `https://${host}${ctx.req?.url}`
  const currentSubdomain = host.includes('.') ? host.split('.').shift() : DEFAULT_APP_SUBDOMAIN
  const jwtToken = jwtFromContext(ctx)

  const lang = ctx.query?.lang
  const existingLanguage = LANGUAGES.find((language) => language.value === lang)

  return {
    host,
    path: new URL(currentUrl).pathname,
    currentUrl,
    currentSubdomain,
    query: ctx.query,
    jwtToken,
    initialLocale:
      parseValidLocale(ctx.req?.cookies[LOCALE_COOKIE_NAME]) ??
      existingLanguage?.value ??
      FALLBACK_LANG,
  }
}
export const jwtFromContext = (ctx: GetServerSidePropsContext) => {
  return parseCookies(ctx)[AUTH_TOKEN_KEY] || parseCookies(ctx)[AUTH_TOKEN_RESTRICTED_KEY] || ''
}

export type CombinedProps = ServerUserProps &
  SubdomainStatusProps &
  ProviderUserProps & {
    disableColorPicker?: boolean
  }
type ResultConditions<R> = (props: CombinedProps & R) => Promise<ServerPropsResult<R> | void>

export type CreatePageOptions<R> = {
  loadUser: boolean
  // eslint-disable-next-line @typescript-eslint/ban-types
  userConditions?: ResultConditions<{}>
  loadSubdomainStatus?: boolean
  loadSubdomainUser?: boolean
  disableColorPicker?: boolean
  loadData?: (props: CombinedProps) => Promise<R>
  dataConditions?: ResultConditions<R>
  allowedUser?: 'only-provider' | 'only-customer'
  allowedSubdomain?: 'only-app' | 'only-custom'
  nonAuthenticated?: 'redirect-to-login' | 'show-not-found'
  redirects?: {
    /**
     * Send user without confirmed email to getting started page
     * @default true
     */
    onboarding?: boolean
    /**
     * Send user with inactive subscription to subscription page
     * @default true
     */
    subscription?: boolean
  }
}

type ServerPropsResult<R, Q extends ParsedUrlQuery = ParsedUrlQuery> = GetServerSidePropsResult<
  ServerUserProps<Q> | (ServerUserProps<Q> & R)
>

export const createPage = async <R, Q extends ParsedUrlQuery = ParsedUrlQuery>(
  ctx: GetServerSidePropsContext<Q>,
  options: CreatePageOptions<R>,
): Promise<ServerPropsResult<R, (typeof ctx)['query']>> => {
  return await mutex.runExclusive(
    async (): Promise<ServerPropsResult<R, (typeof ctx)['query']>> => {
      const commonProps = await populateCommonProps<(typeof ctx)['query']>(ctx)
      const props: CombinedProps = { ...commonProps }

      options.redirects = {
        // Default redirects
        onboarding: true,
        subscription: true,
        ...options.redirects,
      }

      props.disableColorPicker = options.disableColorPicker ?? false

      // Subdomain
      if (options.allowedSubdomain) {
        if (isApp(props)) {
          if (options.allowedSubdomain === 'only-custom') {
            return { notFound: true }
          }
        } else {
          if (options.allowedSubdomain === 'only-app') {
            return { notFound: true }
          }
        }
      }

      setupServerResponseInterceptor()

      // Subdomain status
      if (options.loadSubdomainStatus && !isApp(props)) {
        // Fetch custom subdomain status
        try {
          const subdomainIsActuallyPartOfIpAddress =
            props.currentSubdomain && /^\d+$/.test(props.currentSubdomain)

          if (subdomainIsActuallyPartOfIpAddress) {
            props.subdomainStatus = null
          } else {
            props.subdomainStatus = (await fetchSubdomainStatus(props.currentSubdomain)) ?? null
          }
          console.debug('subdomainStatus', props.subdomainStatus)
        } catch (error) {
          if (
            'isAxiosError' in error &&
            error.isAxiosError &&
            (error as AxiosError).response?.status === ERROR_CODES.NOT_FOUND
          ) {
            console.error('Subdomain not found:', props.currentSubdomain)
          } else {
            console.error('Subdomain fetch error', error.message, error.config)
          }
        }
        // Fetch owner of subdomain
        if (options.loadSubdomainUser && props.subdomainStatus) {
          if (props.subdomainStatus.status.user_id) {
            try {
              props.providerUser = await fetchProvider(props.subdomainStatus.status.user_id)
              console.debug('providerUser', props.providerUser)
            } catch (error) {
              console.error('Subdomain user fetch error', error.message, error.config)
            }
          }
        }
      }

      // Load server user via jwt
      if (options.loadUser) {
        setupServerAuthentication(props.jwtToken)

        if (props.jwtToken) {
          try {
            props.serverUser = await fetchUserByToken({ jwt: props.jwtToken })
            // console.debug('serverUser', props.serverUser)
          } catch (error) {
            console.error('Server user error', error.message, error.config)
          }
        }
      }

      // Restrict specific types of users to the page
      if (options.allowedUser) {
        if (isCustomer(props)) {
          if (options.allowedUser === 'only-provider') {
            return { notFound: true }
          }
        }
        if (isProvider(props)) {
          if (options.allowedUser === 'only-customer') {
            return { notFound: true }
          }
        }
      }

      // Define non-authenticated behavior
      if (options.nonAuthenticated && !isAuthenticated(props)) {
        if (options.nonAuthenticated === 'show-not-found') {
          console.log('show-not-found')
          return { notFound: true }
        }
        if (options.nonAuthenticated === 'redirect-to-login') {
          return loginRedirect(props)
        }
      }

      // Redirect if provider is not onboarded
      if (options.redirects?.onboarding && isApp(props) && props.serverUser) {
        if (isProvider(props) && !isServerUserOnboarded(props)) {
          return onboardingRedirect()
        }
      }

      // Redirect if provider doesn't have active subscription
      if (options.redirects?.subscription && props.serverUser) {
        if (isProvider(props) && isApp(props) && !isSubscriptionException(props)) {
          return subscriptionRedirect()
        }
      }

      if (options.userConditions) {
        const result = await options.userConditions(props)
        if (result) {
          return result
        }
      }

      if (options.loadData) {
        try {
          const data = await options.loadData(props)
          console.debug('pageData', data)

          const dataProps = { ...props, ...data }
          if (options.dataConditions) {
            const result = await options.dataConditions(dataProps)
            if (result) {
              return result
            }
          }
          return { props: dataProps }
        } catch (error) {
          console.error('Load data error:', error.message, error.config)
        } finally {
          cleanAuthentication()
        }
      }

      return { props }
    },
  )
}

export const redirectBack = (query: ParsedUrlQuery, shouldAllowAbsolutePaths = false) => {
  if (
    typeof query.pathname === 'string' &&
    (shouldAllowAbsolutePaths || query.pathname.startsWith(routes.root)) && // Avoid absolute redirects
    !query.pathname.startsWith('/_next') // Exclude Next.js routes
  ) {
    return query.pathname
  }
  return routes.root
}

export const redirectBackAllowingAbsolute = (query: ParsedUrlQuery) => {
  return redirectBack(query, true)
}

export const isAuthenticated = (props: ServerUserProps): boolean => {
  return !!props.serverUser
}

export const isOnboarded = (flags?: Flags) => {
  if (!flags) {
    return false
  }
  return flags.has_passed_onboard_industry_step && flags.has_passed_onboard_photo_step
}

export const isServerUserOnboarded = (props: ServerUserProps): boolean => {
  return isOnboarded(props.serverUser?.flags)
}

export const onboardingRedirect = () => {
  console.log('onboarding-redirect')
  return {
    redirect: {
      destination: `${routes.domain}${routes.onboard.index}`,
      permanent: false,
    },
  }
}

export const isSubscriptionException = (props: ServerUserProps): boolean => {
  if (redirectExceptions.app.includes(props.path)) {
    return true
  }
  // Exclude other
  if (props.path === routes.root && !isApp(props)) {
    return true
  }
  return isAuthenticated(props) && isProvider(props) && isSubscriptionActive(props)
}
export const subscriptionRedirect = () => {
  console.log('subscription-redirect')
  return {
    redirect: {
      destination: `${routes.domain}${routes.subscription}`,
      permanent: false,
    },
  }
}

const loginRedirectUrl = (pathname?: string): string => {
  const loginPage = `${routes.domain}${routes.login.index}`
  if (pathname && pathname.startsWith(routes.root) && !pathname.startsWith('/_next')) {
    return `${loginPage}?pathname=${pathname}`
  }
  return loginPage
}

export const loginRedirect = (props: ServerUserProps) => {
  console.log('redirect-to-login')
  return {
    redirect: {
      destination: loginRedirectUrl(props.path),
      permanent: false,
    },
  }
}

export const isProvider = (props: ServerUserProps): boolean => {
  return props.serverUser?.user_type.id === USER_ROLE.PROVIDER
}
export const isSubscriptionActive = (props: ServerUserProps): boolean => {
  return !!props.serverUser?.subscription?.should_give_access
}

export const isCustomer = (props: ServerUserProps): boolean => {
  return props.serverUser?.user_type.id === USER_ROLE.CUSTOMER
}

export const isApp = (props: CommonPageProps<ParsedUrlQuery>): boolean => {
  return props.currentSubdomain === DEFAULT_APP_SUBDOMAIN
}
